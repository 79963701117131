<template>
  <PostsFilter :paginator="paginator">
    <template v-slot:default="{ taxonomies, toggle, selectedTerms, loading, paginator }">
      <div class="paginator-posts-filter">
        <div class="paginator-posts-filter__inner">
          <template v-for="taxonomyOption in taxonomies" :key="taxonomyOption.slug">
            <Dropdown
                v-if="taxonomyOption.options?.length && !singleTaxonomySelect"
                v-model="selectedTerms[taxonomyOption.slug]"
                :options="[{ id: 'all', label: $t('all'), disabled: false }, ...taxonomyOption.options]"
                optionLabel="label"
                optionValue="id"
                optionDisabled="disabled"
                :loading="loading"
                :disabled="loading"
                :placeholder="taxonomyOption.label"
                @change="(e) => handleChange(taxonomyOption.slug, e.value, toggle, paginator)"
            />
            <SingleTaxonomySelect
                v-if="taxonomyOption.options?.length && singleTaxonomySelect"
                v-model="selectedTerms[taxonomyOption.slug]"
                :options="taxonomyOption.options"
                optionLabel="label"
                optionValue="id"
                optionDisabled="disabled"
                :loading="loading"
                :disabled="loading"
                :placeholder="taxonomyOption.label"
                @change="(e) => toggle(taxonomyOption.slug, e.value)"
            />
          </template>
        </div>
      </div>
      <div v-if="showSelectedOption && selectedOption">
        <h3>{{ selectedTaxonomy}}: {{ selectedOption }}</h3>
      </div>
    </template>
  </PostsFilter>
</template>

<script lang="ts" setup>
import {PostsFilter, PaginatorType} from 'wly-theme-extensions';
import Dropdown from '@/js/vue/components/Dropdown.vue';
import SingleTaxonomySelect from "@/js/vue/components/SingleTaxonomySelect.vue";
import {PropType, watch, ref} from "vue";

const props = defineProps({
  paginator: {
    type: Object as PropType<PaginatorType>,
    required: true,
  },
  singleTaxonomySelect: {
    type: Boolean,
    default: () => false
  },
  showSelectedOption: {
    type: Boolean,
    default: () => true
  }
});

const {taxonomyFilterItems} = props.paginator;

const selectedOption = ref(null);
const selectedTaxonomy = ref(null);

const handleChange = (taxonomySlug, value, toggleFn, paginator) => {
  if (value === 'all') {
    paginator.taxonomyFilterItems.value[taxonomySlug] = [];
    paginator.toFirstPage();

    if (selectedTaxonomy.value === taxonomySlug) {
      selectedOption.value = null;
      selectedTaxonomy.value = null;
    }
  } else if (Array.isArray(value)) {
    if (value.includes('all')) {
      paginator.taxonomyFilterItems.value[taxonomySlug] = [];
      paginator.toFirstPage();
    } else {
      toggleFn(taxonomySlug, value);
    }
  } else {
    toggleFn(taxonomySlug, value);
  }
};

watch(taxonomyFilterItems.value, (v) => {
  if (Object.keys(v).length !== 1 || props.singleTaxonomySelect)
    return;

  for (const prop in v) {
    if (v[prop].length === 0) {
      selectedTaxonomy.value = null;
      selectedOption.value = null;
      continue;
    }

    const taxonomie = props.paginator.taxonomies.find((t) => t.slug === prop);
    if (!taxonomie) continue;

    const term = taxonomie.terms.find((t) => t.term_id === v[prop][0]);
    if (!term) continue;

    selectedTaxonomy.value = taxonomie.label;
    selectedOption.value = term.name;
  }
});
</script>

<style lang="scss" scoped>
.wte-dropdown {
  max-width: 100%;
}
</style>