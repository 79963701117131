<template>
  <form class="store-search__form" id="pac-form" method="get" @submit.prevent="submit" data-bound-min-lat="" data-bound-min-lon="" data-bound-max-lat="" data-bound-max-lon="">
    <div class="search-field__wrapper">
      <div class="search-field">
        <input type="hidden" name="post_type" value="wly_map_location" />
        <label for="pac-input">{{ $t('location_search_plz') }}</label>
        <input ref="input" id="pac-input" name="s" type="search" class="form-control" v-model="search" :placeholder="$t('location_search_placeholder')" required />
      </div>
      <button type="submit" id="searchsubmit" class="button button--primary store-search__button" value="">
        {{ $t('location_search_button') }}
      </button>
    </div>
  </form>
  <div class="location__items">
    <component v-for="location in sortLocationByDistance"
               :is="toPostComponent(location.id)"
               :distance="location.distance"
               :key="location.id"
    >
    </component>
  </div>
  <div v-if="numberOfDisplayedLocations < props.locations.length" class="button--load-more">
    <button class="button button--secondary" @click="loadMore()">{{ $t('load_more') }}</button>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, defineComponent, onBeforeMount } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';

const props = defineProps({
  placeholder: {
    type: String,
    default: () => 'Dein Standort',
  },
  googleApiKey: {
    type: String,
    required: true,
  },
  initial: {
    type: Number,
    required: true,
  },
  more: {
    type: Number,
    required: true,
  },
  locations: {
    type: Array<any>,
    required: true,
    default: () => [],
  },
});

const toPostComponent = (id: number) => components[id]

const input = ref<HTMLElement>(null);
let googleInstance;
let autocomplete;
let selectedPlace = ref(null);
let selectedPlaceLng = ref(null);
let selectedPlaceLat = ref(null);
let search = ref('');

let numberOfDisplayedLocations = ref(props.initial);


const loadMore = () => {
  numberOfDisplayedLocations.value += props.more;
};
const submit = () => {
  let place = autocomplete.getPlace();
  if (!place || !place.geometry) {
    handleNoPlaceSelected()
  } else {
    selectedPlace.value = place;
    // Set Filter Options
    selectedPlaceLng.value = place.geometry.location.lng();
    selectedPlaceLat.value = place.geometry.location.lat();
    numberOfDisplayedLocations.value = props.initial
    search.value = place.formatted_address
  }
};

const handleNoPlaceSelected = () => {
  let options = {
    types: ['(regions)'],
    componentRestrictions: { country: 'ch' },
  };
  const service = new googleInstance.maps.places.AutocompleteService();
  service.getPlacePredictions({ input: search.value, ...options }, (predictions, status) => {
    const firstPrediction = predictions[0]
    search.value = firstPrediction.description
    const placesService = new googleInstance.maps.places.PlacesService(input.value)
    placesService.getDetails({placeId: firstPrediction.place_id}, (place) => {
      selectedPlace.value = place;
      // Set Filter Options
      selectedPlaceLng.value = place.geometry.location.lng();
      selectedPlaceLat.value = place.geometry.location.lat();
      numberOfDisplayedLocations.value = props.initial
    })
  })
}

const distance = (lat1, lon1, lat2, lon2, unit) => {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit == 'K') {
    dist = dist * 1.609344;
  }
  if (unit == 'N') {
    dist = dist * 0.8684;
  }
  return dist;
};

const initPlacesDropdown = (google) => {
  const formElement = document.getElementById('pac-form');
  let defaultBounds = new google.maps.LatLngBounds(
    new google.maps.LatLng(formElement.dataset.boundMinLat, formElement.dataset.boundMinLon),
    new google.maps.LatLng(formElement.dataset.boundMaxLat, formElement.dataset.boundMaxLon)
  );
  let options = {
    bounds: defaultBounds,
    types: ['(regions)'],
    componentRestrictions: { country: 'ch' },
  };
  autocomplete = new google.maps.places.Autocomplete(input.value, options);
  autocomplete.addListener('place_changed', () => {
    const place = autocomplete.getPlace();
    if (!place.geometry) return;
    submit();
  });
};

const sortLocationByDistance = computed(() => {
  let locations = props.locations;
  if (selectedPlace.value) {
    locations = props.locations.sort((place1, place2) => {
      const distance1 = distance(selectedPlaceLat.value, selectedPlaceLng.value, place1.latitude, place1.longitude, 'K');
      place1.distance = distance1;
      const distance2 = distance(selectedPlaceLat.value, selectedPlaceLng.value, place2.latitude, place2.longitude, 'K');
      place2.distance = distance2;
      return distance1 - distance2;
    });
  }

  return locations.slice(0, numberOfDisplayedLocations.value)
});

const loader = new Loader({
  apiKey: props.googleApiKey,
  version: 'weekly',
  libraries: ['places'],
});

const components = {}

const definePostComponent = (template: string) =>
  defineComponent({
    name: 'wte-location-search',
    props: {
      distance: {
        type: Number
      }
    },
    template,
  })

onBeforeMount(() => {
  props.locations.forEach(location => {
    if(!components.hasOwnProperty(location.id)) {
      components[location.id] = definePostComponent(location.content);
    }
  });
})

onMounted(() => {
  loader.load().then((google) => {
    googleInstance = google;
    initPlacesDropdown(google);
  });
});
</script>
